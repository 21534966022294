import { device } from '../../../../../styles/breakpoints'
import styled from 'styled-components'
import { colorScheme } from '../../styles'

export const ContentHeader = styled.section`
  color: white;
  display: flex;
  flex-direction: column;
  background-color: ${colorScheme.darkOrange};
  padding: 56px 0;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;

  a, button {
    height: 40px;
    width: 100%;
    @media ${device.tablet} {
      height: 48px;
    }
  }

  @media ${device.tablet} {
    padding: 28px 0;
  }

  @media ${device.desktopLG} {
    padding: 36px 0;
  }

  @media ${device.desktopXL} {
    padding: 54px 0;
  }

  .grid-container {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 22px;

    @media ${device.desktopLG} {
      grid-gap: 28px;
    }

    @media ${device.desktopXL} {
      grid-gap: 40px;
    }

    .card-grid {
      background-color: white;
      border-radius: 8px;
      padding: 16px;

      @media ${device.tablet} {
        padding: 20px;
      }

      span {
        color: #904C0F;
      }
    }
  }
`
