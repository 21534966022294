import { orange } from 'src/styles/colors'
import { device } from '../../../../../styles/breakpoints'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 56px 0;

  @media ${device.tablet} {
    padding: 56px 0;
  }

  @media ${device.desktopLG} {
    padding: 68px 0;
  }

  @media ${device.desktopXL} {
    padding: 134px 0;
  }
  
  h2 {
    color: #904C0F;
  }

  .transparent-btn {
    border: 1px solid ${orange.extra};
  }

  a, button {
    height: 40px;

    @media ${device.desktopLG} { 
      height: 48px;
    }
  }
`
