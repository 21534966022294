import { breakpoints, device } from '../../../../../styles/breakpoints'
import styled from 'styled-components'
import { colorScheme } from '../../styles'

export const ContentHeader = styled.section`
  color: white;
  display: flex;
  flex-direction: column;
  background-color: ${colorScheme.darkOrange};
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  padding: 56px 0;

  @media ${device.tablet} {
    padding: 0;
  }
  
  h1, span, p{
    color: white;
  }

  a, button, .button-transparent {
    height: 40px;

    @media ${device.desktopLG} { 
      height: 48px;
    }
  }

  @media (min-width: ${breakpoints.md}){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-geral-dobra1/image.webp);
    background-size: contain;
    background-position-x: right;
    height: 374px;
  }
  @media (min-width: 1024px){
    height: 530px;
  }
  @media (min-width: ${breakpoints.xxl}){
    height: 702px;
  }
`
