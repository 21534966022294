import React from 'react'
import { ImgWebp } from 'src/styles/imgWebp'

import IcChecklist from '@interco/icons/core/action-navigation/ic_checklist'
import IcCheckCircle from '@interco/icons/core/status/ic_check_circle'
import IcHandConfig from '@interco/icons/core/human/ic_hand_config'
import IcContractArrowUp from '@interco/icons/core/files/ic_contract_arrow_up'
import IcBarsMoney from '@interco/icons/core/finance/ic_bars_money'
import IcDesktop from '@interco/icons/core/media-communication/ic_desktop'

import { ContentHeader } from './styles'

interface IEmpreendaComUmaSolucaoList {
  key: string;
  icon: JSX.Element;
  text: string;
}

const EmpreendaComUmaSolucaoList: IEmpreendaComUmaSolucaoList[] = [
  {
    key: 'sistemas-otimizados',
    icon: <IcChecklist height={24} width={24} color='#904C0F' />,
    text: 'Sistemas otimizados',
  },
  {
    key: 'plataforma-intuitiva',
    icon: <IcCheckCircle height={24} width={24} color='#904C0F' />,
    text: 'Plataforma intuitiva',
  },
  {
    key: 'time-de-suporte',
    icon: <IcHandConfig height={24} width={24} color='#904C0F' />,
    text: 'Time de suporte',
  },
  {
    key: 'melhorias-continuas',
    icon: <IcContractArrowUp height={24} width={24} color='#904C0F' />,
    text: 'Melhorias contínuas',
  },
  {
    key: 'taxas-competitivas',
    icon: <IcBarsMoney height={24} width={24} color='#904C0F' />,
    text: 'Taxas competitivas',
  },
  {
    key: 'portal-desenvolvedor',
    icon: <IcDesktop height={24} width={24} color='#904C0F' />,
    text: 'Portal do desenvolvedor',
  },
]

const EmpreendaComUmaSolucao = () => {
  return (
    <ContentHeader
      id='ampreenda-com-uma-solucao'
      aria-label='Mulher sorridente olhando para tela do seu notebook enquanto segura seu smartphone.'
    >
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-8 col-xl-7'>
            <h2 className='fs-24 lh-28 fs-lg-28 lh-lg-33 fs-xl-48 lh-xl-52 fw-700 font-citrina mb-4'>Empreenda com uma solução <span className='d-block'>de integração completa</span></h2>
            <p className='fs-10 lh-12 fs-md-12 lh-md-14 fs-lg-14 lh-lg-16 fs-xl-16 lh-xl-20 text-grayscale--400'>Com as APIs, a sua empresa poderá desenvolver aplicações que conversam diretamente com o Inter, sem precisar acessar o Internet Banking ou o Super App. Ou seja, você pode conectar o seu ERP e ter uma experiência completa. Além disso, sua empresa aproveita de:</p>
            <div className='card-container mt-4'>
              {EmpreendaComUmaSolucaoList.map((item: IEmpreendaComUmaSolucaoList) => (
                <div key={item.key} className='d-flex align-items-center'>
                  <div className='mr-2'>{item.icon}</div>
                  <h3 className='fs-10 lh-12 fs-lg-12 lh-lg-14 fs-xl-14 lh-xl-16 fw-600 font-inter mb-0'>{item.text}</h3>
                </div>
              ))}
            </div>
          </div>
          <div className='col-12 col-md-4 col-xl-5 order-md-first mt-5 mt-md-0'>
            <ImgWebp
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/api-geral-dobra2/image.webp'
              breakPointsSize={{ sm: '100%', md: '220px', lg: '294px', xl: '414px' }}
              alt='Computador com a home Pix do Inter empresas.'
            />
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default EmpreendaComUmaSolucao
