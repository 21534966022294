import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { ContentHeader } from './styles'
import { OrangeButtonAsLink, WhiteTransparentButton } from '../../styles'
import slugify from 'slugify'

interface IIntegracoes {
  title: string;
  description: string;
}

interface IAlgumasOutrasIntegracoes {
  setModalOpen: (value: boolean) => void;
}

const integracoesList: IIntegracoes[] = [
  {
    title: 'API para Pagamentos',
    description: 'Seus pagamentos, simples ou em lote, com códigos de barra ou de DARF "campo a campo" podem ser realizados de forma simplificada com essa API.',
  },
  {
    title: 'API para Saldo',
    description: 'Centralize a consulta de saldo do seu negócio em um único sistema, simplificando a análise de gastos da sua empresa.',
  },
  {
    title: 'API para Extrato',
    description: 'Realize consultas simples, detalhadas e recupere extratos em PDF de até 90 dias de forma simples.',
  },
]

const AlgumasOutrasIntegracoes = ({ setModalOpen }: IAlgumasOutrasIntegracoes) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <ContentHeader
      id='hero'
      aria-label='Mulher sorridente olhando para tela do seu notebook enquanto segura seu smartphone.'
    >
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='d-none d-md-block col-md-6'>
            <div className='grid-container'>
              {integracoesList.map((integracao: IIntegracoes) => (
                <div key={slugify(integracao.title)} className='card-grid'>
                  <span className='fs-20 lh-24 fs-lg-24 lh-lg-28 fs-xl-28 lh-xl-34 font-citrina fw-700 mb-3 d-block' dangerouslySetInnerHTML={{ __html: integracao.title }} />
                  <p className='fs-10 lh-12 fs-lg-12 lh-lg-15 fs-xl-18 lh-xl-22 text-grayscale--40 0 mb-0' dangerouslySetInnerHTML={{ __html: integracao.description }} />
                </div>
              ))}
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1'>
            <h2 className='fs-24 lh-28 fs-md-20 lh-md-24 fs-lg-28 lh-lg-34 fs-xl-40 lh-xl-44 fw-700 font-citrina text-white mb-4'>Algumas outras integrações de API do Inter</h2>
            <div className='d-md-none'>
              <div className='grid-container mb-4'>
                {integracoesList.map((integracao: IIntegracoes) => (
                  <div key={slugify(integracao.title)} className='card-grid'>
                    <span className='fs-20 lh-24 fs-lg-24 lh-lg-28 fs-xl-28 lh-xl-34 font-citrina fw-700 mb-3 d-block' dangerouslySetInnerHTML={{ __html: integracao.title }} />
                    <p className='fs-10 lh-12 fs-lg-12 lh-lg-15 fs-xl-18 lh-xl-22 text-grayscale--400 mb-0' dangerouslySetInnerHTML={{ __html: integracao.description }} />
                  </div>
              ))}
              </div>
            </div>
            <OrangeButtonAsLink
              href='https://developers.inter.co/references/token'
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                setModalOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_06',
                  element_action: 'click button',
                  section_name: 'Algumas outras integrações de API do Inter',
                  element_name: 'Quero saber como integrar',
                  redirect_url: 'https://developers.inter.co/references/token',
                })
              }}
            >
              Quero saber como integrar
            </OrangeButtonAsLink>
            <WhiteTransparentButton
              as='button'
              onClick={() => {
                setModalOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_06',
                  element_action: 'click button',
                  section_name: 'Algumas outras integrações de API do Inter',
                  element_name: 'Falar com um especialista',
                })
              }}
            >
              Falar com um especialista
            </WhiteTransparentButton>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default AlgumasOutrasIntegracoes
