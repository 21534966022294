import { breakpoints, device } from '../../../../../styles/breakpoints'
import styled from 'styled-components'
import { colorScheme } from '../../styles'
import { grayscale } from 'src/styles/colors'

export const ContentHeader = styled.section`
  color: white;
  display: flex;
  flex-direction: column;
  background-color: ${colorScheme.darkOrange};
  padding: 24px 0;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;

  a, button {
    height: 40px;

    @media ${device.desktopLG} { 
      height: 48px;
    }
  }

  .main-button {
    width: 100%;
    @media ${device.tablet} {
      width: 250px;
    }

    @media ${device.desktopLG} {
      height: 48px;
      width: 334px;
    }

    @media ${device.desktopXL} {
      width: 470px;
    }
  }
  
  h1, span, p{
    color: white;
  }

  @media (min-width: ${breakpoints.md}){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-geral-dobra3-1440/image.webp);
    background-size: cover;
    height: 400px;
    padding: 48px 0;
  }
  @media (min-width: 1024px){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-geral-dobra3-1440/image.webp);
    height: 530px;
  }
  @media (min-width: ${breakpoints.xxl}){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-geral-dobra3-1440/image.webp);
    padding: 94px 0 68px 0;
    height: 748px;
  }

  .background-mobile {
    background-color: white;
    border-radius: 8px;
    padding: 24px 16px;

    @media ${device.tablet} {
      background-color: transparent;
      border-radius: none;
      padding: 0;
    }
  }

  .list-container {
    h3, span {
      color: ${grayscale[600]};
    }

    @media ${device.tablet} {
      display: grid;
      grid-template-columns: repeat(4, 148px);
      justify-content: center;
      grid-column-gap: 18px;
      margin-bottom: 32px;
    }

    @media ${device.desktopLG} {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 24px;
      margin-bottom: 36px;
    }

    @media ${device.desktopXL} {
      grid-column-gap: 32px;
      margin-bottom: 64px;
    }

    .card-background {
      @media ${device.tablet} {
        background-color: white;
        border-radius: 12px;
        padding: 18px;
      }

      @media ${device.desktopLG} {
        padding: 24px;
      }

      @media ${device.desktopXL} {
        padding: 40px;
      }
    }
  }
`
