import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { ImgWebp } from 'src/styles/imgWebp'
import { ContentHeader } from './styles'
import { OrangeButtonAsLink } from '../../styles'

const SeuCaixaMaisTransparente = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <ContentHeader>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 mb-4 mb-md-0'>
            <ImgWebp
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/api-geral-dobra5/image.webp'
              breakPointsSize={{ sm: '100%', md: '300px', lg: '400px', xl: '536px' }}
              alt='Tela da home do Super App Inter Empresas.'
            />
          </div>
          <div className='col-12 col-md-5 offset-md-1'>
            <h2 className='fs-24 lh-28 fs-lg-28 lh-lg-33 fs-xl-40 lh-xl-44 fw-700 font-citrina mb-4'>API Banking: seu caixa mais transparente</h2>
            <p className='fs-10 lh-12 fs-lg-14 lh-lg-17 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4'>Acesse o Saldo, Extrato e Pagamentos de boleto e Pix, em um ambiente único feito pelo Inter Empresas para simplificar a gestão de dados do seu negócio.</p>
            <OrangeButtonAsLink
              href='https://conta-digital-pj.inter.co/login'
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  element_action: 'click button',
                  section_name: 'API Banking: seu caixa mais transparente',
                  element_name: 'Abrir conta PJ',
                  redirect_url: 'https://conta-digital-pj.inter.co/login',
                })
              }}
            >
              Abrir conta PJ
            </OrangeButtonAsLink>
            <div>
              <a
                className='transparent-btn btn btn--lg bg-transparent text-none text-orange--extra mt-3 rounded-2 fs-14 fw-700 mw-100'
                href='https://developers.inter.co/references/token'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    element_action: 'click button',
                    section_name: 'API Banking: seu caixa mais transparente',
                    element_name: 'Saber mais',
                  })
                }}
              >
                Saber mais
              </a>
            </div>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default SeuCaixaMaisTransparente
