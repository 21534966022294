import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { ImgWebp } from 'src/styles/imgWebp'

import { ContentHeader } from './styles'
import { OrangeButtonAsLink } from '../../styles'

import IcCashback from '@interco/icons/core/finance/ic_cashback'
import IcCheckCircle from '@interco/icons/core/status/ic_check_circle'
import IcHandConfig from '@interco/icons/core/human/ic_hand_config'
import IcContractArrowUp from '@interco/icons/core/files/ic_contract_arrow_up'
import IcBarsMoney from '@interco/icons/core/finance/ic_bars_money'
import IcCloseCircle from '@interco/icons/core/status/ic_close_circle'
interface IEmpreendaComUmaSolucaoList {
  key: string;
  icon: JSX.Element;
  text: string;
}

const EmpreendaComUmaSolucaoList: IEmpreendaComUmaSolucaoList[] = [
  {
    key: 'webhook-cobrancas-pagas-canceladas',
    icon: <IcCashback height={24} width={24} color='#904C0F' />,
    text: 'Webhook das cobranças pagas/canceladas',
  },
  {
    key: 'recuperar-uma-colecao-de-cobrancas',
    icon: <IcCheckCircle height={24} width={24} color='#904C0F' />,
    text: 'Recuperar uma coleção de cobranças',
  },
  {
    key: 'consultar-sumario-cobrancas',
    icon: <IcHandConfig height={24} width={24} color='#904C0F' />,
    text: 'Consultar sumário de cobranças',
  },
  {
    key: 'consultar-cobrancas-detalhadas',
    icon: <IcContractArrowUp height={24} width={24} color='#904C0F' />,
    text: 'Consultar cobranças detalhadas',
  },
  {
    key: 'gerar-pdf-cobrancas',
    icon: <IcBarsMoney height={24} width={24} color='#904C0F' />,
    text: 'Gerar PDF de cobranças',
  },
  {
    key: 'cancelar-cobrancas',
    icon: <IcCloseCircle height={24} width={24} color='#904C0F' />,
    text: 'Cancelar cobranças',
  },
]

const APIParaCobranca = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <ContentHeader
      id='hero'
      aria-label='Mulher sorridente olhando para tela do seu notebook enquanto segura seu smartphone.'
    >
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 lh-28 fs-lg-28 lh-lg-33 fs-xl-40 lh-xl-44 fw-700 font-citrina mb-4'>API para Cobranças</h2>
            <p className='fs-10 lh-12 fs-lg-14 lh-lg-17 fs-xl-18 lh-xl-22 text-grayscale--400'>Simplifique suas cobranças com emissão ágil e atendendo às demandas do seu negócio de forma prática. E agora, você também pode emitir boletos com pix! Sua empresa pode:</p>
            <div className='card-container mt-4'>
              {EmpreendaComUmaSolucaoList.map((item: IEmpreendaComUmaSolucaoList) => (
                <div key={item.key} className='d-flex align-items-center'>
                  <div className='mr-2'>{item.icon}</div>
                  <h3 className='fs-10 lh-12 fs-lg-12 lh-lg-14 fs-xl-14 lh-xl-16 fw-600 font-inter mb-0'>{item.text}</h3>
                </div>
              ))}
            </div>
            <div className='mt-4'>
              <OrangeButtonAsLink
                href='https://developers.inter.co/references/token'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  element_action: 'click button',
                  section_name: 'API para Cobranças',
                  element_name: 'Quero saber como integrar',
                  redirect_url: 'https://developers.inter.co/references/token',
                })
                }}
              >
                Quero saber como integrar
              </OrangeButtonAsLink>
            </div>
          </div>
          <div className='d-none d-md-flex justify-content-md-end col-12 col-md-6 offset-lg-1'>
            <ImgWebp
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/api-geral-dobra4/image.webp'
              breakPointsSize={{ sm: '100%', md: '300px', lg: '400px', xl: '536px' }}
              alt='Homem sentado em um sofá, enquanto usa seu smartphone.'
            />
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default APIParaCobranca
