import { grayscale } from 'src/styles/colors'
import { device } from '../../../../../styles/breakpoints'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  padding: 56px 0;

  @media ${device.tablet} {
    padding: 46px 0;
  }

  @media ${device.desktopLG} {
    padding: 62px 0;
  }
  
  h2 {
    color: #904C0F;
  }

  .card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media ${device.tablet} {
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 12px;
    }

    h3 {
      color: ${grayscale[600]};
    }
  }
`
