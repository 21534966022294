import { breakpoints } from '../../../../../styles/breakpoints'
import styled from 'styled-components'
import { colorScheme } from '../../styles'

export const ContentHeader = styled.section`
  color: white;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px 0;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  
  h2 {
    color: #904C0F;
  }
`
