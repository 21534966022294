import React from 'react'
import { ContentHeader } from './styles'
import { OrangeButtonAsLink, WhiteTransparentButton } from '../../styles'

import ScrollTo from 'src/components/ScrollTo'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <ContentHeader
      id='hero'
      aria-label='Mulher sorridente olhando para tela do seu notebook enquanto segura seu smartphone.'
      className='justify-content-md-center'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h1 className='fs-24 lh-28 fs-lg-28 lh-lg-33 fs-xl-48 lh-xl-52 fw-700 font-citrina text-white mb-3'>APIs Inter Empresas: <span className='d-md-block'>integração e evolução.</span></h1>
            <p className='fs-10 lh-12 fs-lg-12 lh-lg-14 fs-xl-18 lh-xl-22 fw-400 mb-4 pb-2'>No Inter Empresas, o seu negócio conta com tecnologia de ponta para economizar tempo e garantir uma visão mais precisa da sua gestão financeira.</p>
            <OrangeButtonAsLink
              href='https://conta-digital-pj.inter.co/login'
              target='_blank'
              rel='noreferrer'
              className='w-100'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'hero',
                  element_action: 'click button',
                  section_name: 'APIs Inter Empresas: integração e evolução.',
                  element_name: 'Abrir conta PJ',
                  redirect_url: 'https://conta-digital-pj.inter.co/login',
                })
              }}
            >
              Abrir conta PJ
            </OrangeButtonAsLink>

            <ScrollTo
              to='#ampreenda-com-uma-solucao'
              section='hero'
              sectionName='API Pix Inter Empresas'
              elementName='Saber mais'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'hero',
                  element_action: 'click scroll',
                  section_name: 'APIs Inter Empresas: integração e evolução.',
                  element_name: 'Saber mais',
              })
              }}
            >
              <WhiteTransparentButton className='button-transparent w-100'>
                Saber mais
              </WhiteTransparentButton>
            </ScrollTo>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Hero
