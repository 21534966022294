import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const ContentHeader = styled.section`
  color: white;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 56px 0;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;

  @media ${device.tablet} {
    padding: 68px 0;
  }

  @media ${device.desktopLG} {
    padding: 84px 0;
  }

  @media ${device.tablet} {
    padding: 106px 0;
  }
  
  h2 {
    color: #904C0F;
  }

  a, button {
    height: 40px;

    @media ${device.desktopLG} { 
      height: 48px;
    }
  }

  .card-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    h3 {
      color: ${grayscale[600]};
    }
  }
`
