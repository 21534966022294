import React, { useState, useEffect } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { ContentHeader } from './styles'
import { OrangeButtonAsLink } from '../../styles'

import IcCoinAdd from '@interco/icons/core/finance/ic_coin_add'
import IcCoinRemove from '@interco/icons/core/finance/ic_coin_remove'
import IcRotateMoney from '@interco/icons/core/finance/ic_rotate_money'
import IcCheckCircle from '@interco/icons/core/status/ic_check_circle'

interface IEmpreendaComUmaSolucaoList {
  key: string;
  icon: JSX.Element;
  title: string;
  text: string;
}

interface IApiPixInterEmpresasProps {
  setModalOpen: (value: boolean) => void;
}

const EmpreendaComUmaSolucaoList: IEmpreendaComUmaSolucaoList[] = [
  {
    key: 'sistemas-otimizados',
    icon: <IcCoinAdd height={24} width={24} color='#904C0F' />,
    title: 'Pix Cash-In',
    text: 'Dinheiro no caixa em instantes. Receba suas vendas de forma imediata e automatizada.',
  },
  {
    key: 'plataforma-intuitiva',
    icon: <IcCoinRemove height={24} width={24} color='#904C0F' />,
    title: 'Pix Cash-Out',
    text: 'Faça transferências via Pix diretamente da API.',
  },
  {
    key: 'time-de-suporte',
    icon: <IcRotateMoney height={24} width={24} color='#904C0F' />,
    title: 'Pix Cob e CobV',
    text: 'Utilizando o QR Code, gere cobranças imediatas e com data de vencimento, juros, multas e até descontos.',
  },
  {
    key: 'melhorias-continuas',
    icon: <IcCheckCircle height={24} width={24} color='#904C0F' />,
    title: 'Ambiente Sandbox',
    text: 'Aproveite para homologar sua API em um ambiente de teste do Inter Empresas.',
  },
]

const ApiPixInterEmpresas = ({ setModalOpen }: IApiPixInterEmpresasProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isScreenWide, setIsScreenWide ] = useState<boolean>(true)

  useEffect(() => {
    const handleResize = () => {
      setIsScreenWide(window.innerWidth >= 768)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ContentHeader
      id='hero'
      role='img'
      aria-label='Uma mulher segurando sua mala, enquanto aguarda o seu voo no aeroporto.'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-20 lh-24 fs-md-24 lh-md-28 fs-lg-28 lh-lg-33 fs-xl-40 lh-xl-44 fw-700 text-white font-citrina mb-4 mb-md-5'>
              <span className='d-md-block'>API Pix Inter Empresas.</span> Agilidade para receber dinheiro e economizar tempo.
            </h2>
            <div className='background-mobile'>
              {isScreenWide ? (
                <div className='list-container'>
                  {EmpreendaComUmaSolucaoList.map((item: IEmpreendaComUmaSolucaoList) => (
                    <div key={item.key} className='d-flex flex-md-column card-background'>
                      <div className='d-md-flex align-items-center mb-2'>
                        <div className='mr-2'>{item.icon}</div>
                        <h3 className='fs-md-10 lh-md-12 fs-lg-14 lh-lg-17 fs-xl-18 lh-xl-22 font-inter fw-600 mb-0'>{item.title}</h3>
                      </div>
                      <div>
                        <h3 className='fs-10 lh-12 fs-lg-12 lh-lg-14 fs-xl-14 lh-xl-16 fw-400 mb-0'>{item.text}</h3>
                      </div>
                    </div>))}
                </div>
              ) : (
                <div className='list-container'>
                  {EmpreendaComUmaSolucaoList.map((item: IEmpreendaComUmaSolucaoList) => (
                    <div key={item.key} className='d-flex mb-0'>
                      <div className='mr-3'>{item.icon}</div>
                      <div>
                        <h3 className='fs-10 lh-12 fs-lg-12 lh-lg-14 fs-xl-18 lh-xl-22 font-inter fw-400 mb-3'><span className='fw-700'>{item.title}: </span>{item.text}</h3>
                      </div>
                    </div>))}
                </div>
              )
            }
              <div className='d-flex justify-content-center'>
                <OrangeButtonAsLink
                  as='button'
                  className='main-button'
                  onClick={() => {
                    setModalOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      element_action: 'click button',
                      section_name: 'APIs Inter Empresas: Agilidade para receber dinheiro e economizar tempo.',
                      element_name: 'Falar com um especialista',
                    })
                  }}
                >
                  Falar com um especialista
                </OrangeButtonAsLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default ApiPixInterEmpresas
